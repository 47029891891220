@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

// Reset css
* {
  padding: 0;
  margin: 0;
}
html,
body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  background: #000;
}
ol,
ul {
  list-style: none;
}
img {
  vertical-align: top;
  width: 100%;
  border: none;
}
a {
  color: inherit;
  text-decoration: none;
}
button {
  border: none;
  background: none;
  cursor: pointer;
}
input {
  border: none;
}
a {
  cursor: pointer;
}

.App {
  overflow: hidden;
  .container {
    margin: 0 auto;
    overflow: hidden;
    &.wrapper {
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 24px;
    }
  }
  .page-main {
    .section-intro {
      background-image: url(assets/images/img-bg-1.gif);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 109px 24px 87px;
        @media only screen and (max-width: 712px) {
          padding: 48px 24px;
        }
        .title {
          color: #fff;
          font-size: 64px;
          font-weight: 700;
          line-height: 78.02px;
          text-align: center;
          @media only screen and (max-width: 1200px) {
            font-size: 54px;
          }
          @media only screen and (max-width: 1024px) {
            font-size: 48px;
          }
          @media only screen and (max-width: 924px) {
            font-size: 36px;
          }
          @media only screen and (max-width: 712px) {
            font-size: 24px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 18px;
            line-height: normal;
          }
        }
        .img-intro {
          margin-top: 69px;
          width: 569px;
          @media only screen and (max-width: 634px) {
            width: auto;
          }
          @media only screen and (max-width: 480px) {
            margin-top: 48px;
          }
        }
        .list-social {
          display: flex;
          align-items: center;
          gap: 69px;
          margin-top: 108px;
          @media only screen and (max-width: 576px) {
            gap: 30px;
          }
          @media only screen and (max-width: 480px) {
            margin-top: 48px;
          }
          img {
            height: 60px;
            @media only screen and (max-width: 480px) {
              height: 40px;
            }
          }
        }
      }
    }
    .section-about {
      background-image: url(assets/images/img-bg-2.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      .container {
        padding: 128px 24px 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media only screen and (max-width: 712px) {
          padding: 48px 24px;
        }
        .img-section {
          width: 1634px;
          @media only screen and (max-width: 1680px) {
            width: auto;
          }
        }
        .content {
          position: relative;
          margin-top: 127px;
          display: flex;
          gap: 35px;
          padding: 52px;
          background: #f2f5e3;
          border-radius: 24px;
          @media only screen and (max-width: 876px) {
            padding: 36px;
            flex-wrap: wrap;
            justify-content: center;
          }
          @media only screen and (max-width: 576px) {
            margin-top: 68px;
          }
          @media only screen and (max-width: 480px) {
            margin-top: 48px;
            padding: 24px;
          }
          .gif-left {
            position: absolute;
            width: 224px;
            left: -280px;
            top: 55%;
            transform: translateY(-50%);
          }
          .gif-right {
            position: absolute;
            width: 224px;
            right: -280px;
            top: 55%;
            transform: translateY(-50%);
          }
          .left {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 32px;
            .img-top {
              width: 421px;
            }
            .img-bottom {
              width: 421px;
            }
            @media only screen and (max-width: 1280px) {
              .img-top,
              .img-bottom {
                width: 321px;
              }
            }
            @media only screen and (max-width: 1024px) {
              .img-top,
              .img-bottom {
                width: 221px;
              }
            }
            @media only screen and (max-width: 876px) {
              .img-top,
              .img-bottom {
                width: 421px;
              }
            }
            @media only screen and (max-width: 568px) {
              .img-top,
              .img-bottom {
                width: auto;
              }
            }
          }
          .right {
            max-width: 639px;
            .title {
              color: #cb1919;
              font-size: 36px;
              font-weight: 700;
              line-height: 43.88px;
            }
            p {
              margin-top: 16px;
              color: #1e1e1e;
              font-size: 24px;
              font-weight: 500;
              line-height: 29.26px;
              text-align: left;
            }
            @media only screen and (max-width: 1280px) {
              .title {
                font-size: 28px;
              }
              p {
                font-size: 18px;
                line-height: 24px;
              }
            }
            @media only screen and (max-width: 1024px) {
              .title {
                font-size: 24px;
              }
              p {
                font-size: 16px;
                line-height: 20px;
              }
            }
            @media only screen and (max-width: 876px) {
              .title,
              p {
                text-align: center;
              }
            }
            @media only screen and (max-width: 576px) {
              .title {
                font-size: 18px;
              }
              p {
                font-size: 14px;
                line-height: 18px;
              }
            }
          }
        }
        p {
          margin-top: 109px;
          color: #fff;
          font-size: 64px;
          font-weight: 700;
          line-height: 78.02px;
          text-align: center;
          @media only screen and (max-width: 1200px) {
            font-size: 54px;
          }
          @media only screen and (max-width: 1024px) {
            font-size: 48px;
          }
          @media only screen and (max-width: 924px) {
            font-size: 36px;
            margin-top: 68px;
          }
          @media only screen and (max-width: 712px) {
            font-size: 24px;
            margin-top: 48px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 18px;
            line-height: normal;
          }
        }
      }
    }
    .section-tokenomics {
      background-image: url(assets/images/img-bg-3.jpeg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 1078px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media only screen and (max-width: 1024px) {
        height: auto;
        padding: 180px 0;
      }

      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          color: #cb1919;
          font-size: 64px;
          font-weight: 700;
          line-height: 78.02px;
          text-align: center;
          text-transform: uppercase;
          @media only screen and (max-width: 524px) {
            font-size: 48px;
            line-height: 58px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 36px;
            line-height: 48px;
          }
        }
        .des {
          margin-top: 13px;
          font-size: 24px;
          font-weight: 500;
          line-height: 29.26px;
          text-align: center;
          span {
            font-weight: 700;
            color: #cb1919;
          }
          @media only screen and (max-width: 480px) {
            font-size: 18px;
            line-height: 24px;
            margin-top: 0;
          }
        }
        .list-token {
          margin-top: 30px;
          display: flex;
          align-items: center;
          gap: 42px;
          @media only screen and (max-width: 1280px) {
            gap: 24px;
          }
          @media only screen and (max-width: 1024px) {
            flex-wrap: wrap;
            justify-content: center;
            gap: 12px;
          }
          @media only screen and (max-width: 480px) {
            margin-top: 14px;
            gap: 8px;
          }
          li {
            display: flex;
            align-items: center;
            flex-direction: column;
            .img-token {
              width: 242px;
              position: relative;
              @media only screen and (max-width: 1280px) {
                width: 200px;
              }
            }
            .content {
              margin-top: -100px;
              background: #cb1919;
              width: 372px;
              height: 295px;
              border-radius: 24px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              @media only screen and (max-width: 1280px) {
                width: 300px;
                height: 225px;
                margin-top: -70px;
              }
              @media only screen and (max-width: 1024px) {
                width: 280px;
                height: 205px;
                margin-top: -70px;
              }
              @media only screen and (max-width: 624px) {
                width: 280px;
                height: 150px;
              }
              .title {
                margin-top: 36px;
                color: #fff;
                font-size: 32px;
                font-weight: 500;
                line-height: 39.01px;
                text-align: center;
              }
              .value {
                margin-top: 23px;
                color: #fff;
                font-size: 32px;
                font-weight: 700;
                line-height: 39.01px;
                text-align: center;
              }
              @media only screen and (max-width: 624px) {
                .title {
                  margin-top: 60px;
                  font-size: 26px;
                  line-height: 29px;
                }
                .value {
                  margin-top: 12px;
                  font-size: 24px;
                  line-height: 29px;
                }
              }
            }
          }
        }
        .address {
          margin-top: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px;
          padding: 18px 0;
          background: #cb1919;
          color: #f2f5e3;
          font-size: 24px;
          font-weight: 700;
          line-height: 29.26px;
          text-align: center;
          border-radius: 24px;
          width: 100%;
          span {
            cursor: pointer;
            width: 24px;
          }
          @media only screen and (max-width: 912px) {
            margin-top: 24px;
          }
          @media only screen and (max-width: 624px) {
            padding: 12px 0;
            font-size: 18px;
            line-height: 20px;
          }
          @media only screen and (max-width: 524px) {
            font-size: 14px;
            line-height: 20px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 10px;
            line-height: 18px;
            gap: 8px;
            max-width: fit-content;
            padding: 12px 24px;
            span {
              width: 18px;
            }
          }
        }
      }
    }
    .section-gallery {
      background-image: url(assets/images/img-bg-2.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      .container {
        padding: 190px 24px 66px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        @media only screen and (max-width: 712px) {
          padding: 48px 24px;
        }
        .content {
          @media only screen and (max-width: 1280px) {
            width: auto;
          }
          .gif-left {
            position: absolute;
            width: 234px;
            left: -280px;
            top: 20%;
          }
          .gif-right {
            position: absolute;
            width: 310px;
            right: -320px;
            top: 55%;
            transform: translateY(-50%);
          }
          z-index: 99;
          position: relative;
          background: #f2f5e3;
          padding: 40px 50px 50px;
          border-radius: 24px;
          @media only screen and (max-width: 786px) {
            padding: 20px 30px 30px;
          }
          .title {
            color: #cb1919;
            font-size: 36px;
            font-weight: 700;
            line-height: 43.88px;
            text-align: center;
            text-transform: uppercase;
            @media only screen and (max-width: 480px) {
              font-size: 28px;
              line-height: 32px;
            }
          }
          .list-meme {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            margin-top: 37px;
            max-width: 1100px;
            @media only screen and (max-width: 1280px) {
              max-width: 992px;
            }
            @media only screen and (max-width: 1148px) {
              max-width: 872px;
            }
            @media only screen and (max-width: 1024px) {
              max-width: 752px;
            }
            @media only screen and (max-width: 916px) {
              max-width: 632px;
            }
            @media only screen and (max-width: 786px) {
              justify-content: center;
            }
            li {
              width: 356px;
              @media only screen and (max-width: 1280px) {
                width: 320px;
              }
              @media only screen and (max-width: 1148px) {
                width: 280px;
              }
              @media only screen and (max-width: 1024px) {
                width: 240px;
              }
              @media only screen and (max-width: 916px) {
                width: 200px;
              }
            }
          }
        }
        .img-bottom {
          width: 172px;
          margin-top: 65px;
        }
        .list-social {
          display: flex;
          align-items: center;
          gap: 69px;
          margin-top: 38px;
          @media only screen and (max-width: 576px) {
            gap: 30px;
          }
          @media only screen and (max-width: 480px) {
            margin-top: 48px;
          }
          img {
            height: 60px;
            @media only screen and (max-width: 480px) {
              height: 40px;
            }
          }
        }
        .footer-content {
          margin-top: 37px;
          max-width: 635px;
          color: #fff;
          font-size: 12px;
          font-weight: 500;
          line-height: 14.63px;
          text-align: center;
        }
      }
    }
  }
}
